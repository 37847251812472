// extracted by mini-css-extract-plugin
export var index = "Index-module--index--2LjGE";
export var header = "Index-module--header--3VWz0";
export var image = "Index-module--image--2OtLT";
export var container = "Index-module--container--1zKaF";
export var content = "Index-module--content--1WeXl";
export var heading = "Index-module--heading--sBY1L";
export var paragraph = "Index-module--paragraph--3-cML";
export var button = "Index-module--button--1e5Nq";
export var icon = "Index-module--icon--1azYc";
export var logos = "Index-module--logos--21NgH";
export var logo = "Index-module--logo--3ljE4";
export var bounce = "Index-module--bounce--c3kGI";
export var topImages = "Index-module--topImages--19b2c";
export var topImage = "Index-module--topImage--2Cu-L";
export var ourWorkBlockWrapper = "Index-module--ourWorkBlockWrapper--3Uv7C";
export var ourStoriesBlockWrapper = "Index-module--ourStoriesBlockWrapper--1gnsp";
export var heartbeat = "Index-module--heartbeat--1mPz0";
export var ellipsisOne = "Index-module--ellipsisOne--3bAvS";
export var ellipsisTwo = "Index-module--ellipsisTwo--o7LMX";
export var ellipsisThree = "Index-module--ellipsisThree--1HvUm";
export var top = "Index-module--top--2YwQR";
export var topTwo = "Index-module--topTwo--f3YPI";
export var center = "Index-module--center--4L1oC";
export var centerTwo = "Index-module--centerTwo--3IxSR";
export var bottom = "Index-module--bottom--3X7Me";
export var bottomTwo = "Index-module--bottomTwo--2k4Uh";