import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { IGatsbyImageData } from "gatsby-plugin-image";
import { PageProps } from "gatsby";
import { Ref } from "react";

import smoothScroll from "smoothscroll-polyfill";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useContext } from "react";
import { useLayoutEffect } from "react";

import ThemeContext from "@Js/Context/ThemeContext";

import Seo from "@Js/Component/Seo";

import Gutenberg from "@Js/Component/Gutenberg";
import HowWeDoWhatWeDoBlock from "@Js/Component/Block/HowWeDoWhatWeDoBlock";
import LinkButton from "@Js/Component/Button/LinkButton";
import LongArrowDownIcon from "@Js/Component/Icon/LongArrowDownIcon";
import OurStoriesBlock from "@Js/Component/Block/OurStoriesBlock";
import OurWorkBlock from "@Js/Component/Block/OurWorkBlock";
import Service from "@Js/Type/Service";
import SmallArrowRightIcon from "@Js/Component/Icon/SmallArrowRightIcon";

import DDAImage from "@Img/DDA.svg";
import DOVHJ from "@Img/DOVHJ.svg";
import FDG from "@Img/FDG.svg";

import * as classNames from "@Css/Component/Page/Index.module.scss";

if (!(typeof window == "undefined")) {
    smoothScroll.polyfill();
}

/**
 * @type AllWordpressPostData
 */
type AllWordpressPostData = {
    allWordpressPost: {
        edges: {
            node: {
                content?: string;
                id: string;
                postDetails: {
                    employee?: {
                        featuredImage?: {
                            node: {
                                localFile: {
                                    childImageSharp: {
                                        gatsbyImageData: IGatsbyImageData;
                                    };
                                };
                            };
                        };
                    };
                    thumbnail?: {
                        localFile: {
                            childImageSharp: {
                                gatsbyImageData: IGatsbyImageData;
                            };
                        };
                    };
                };
                title: string;
                uri: string;
            };
        }[];
    };
};

/**
 * @type AllWordpressProjectData
 */
type AllWordpressProjectData = {
    allWordpressProject: {
        edges: {
            node: {
                details: {
                    services: Service[];
                };
                featuredImage?: {
                    node: {
                        localFile: {
                            childImageSharp: {
                                gatsbyImageData: IGatsbyImageData;
                            };
                        };
                    };
                };
                id: string;
                title: string;
                uri: string;
            };
        }[];
    };
};

/**
 * @type WordpressPageData
 */
type WordpressPageData = {
    wordpressPage: {
        content?: string;
        header: {
            button: {
                target: string;
                title: string;
                url: string;
            };
            heading: string;
            image: {
                altText: string;
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                };
            };
            paragraph: string;
        };
        seo: {
            canonical: string;
            metaDesc: string;
            metaKeywords: string;
            metaRobotsNofollow: string;
            metaRobotsNoindex: string;
            opengraphAuthor: string;
            opengraphDescription: string;
            opengraphModifiedTime: string;
            opengraphPublishedTime: string;
            opengraphPublisher: string;
            opengraphSiteName: string;
            opengraphTitle: string;
            opengraphType: string;
            opengraphUrl: string;
            schema: {
                raw: string;
            };
            title: string;
        };
        topImages: {
            topImageOne: {
                altText: string;
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                };
            };
            topImageTwo: {
                altText: string;
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                };
            };
        };
    };
};

/**
 * @type IndexProps
 */
export type IndexProps = PageProps;

/**
 * @const Index
 */
const Index = (props: IndexProps, ref: Ref<HTMLElement>) => {
    const {
        setHeaderBackground,
        setIsLogoColorless,
        setIsMenuColorless
    } = useContext(ThemeContext);

    const data: AllWordpressPostData & AllWordpressProjectData & WordpressPageData = useStaticQuery(graphql`
        query {
            allWordpressPost(limit: 3, sort: { fields: date, order: DESC }) {
                edges {
                    node {
                        content
                        id
                        postDetails {
                            employee {
                                 ... on WordpressEmployee {
                                    featuredImage {
                                        node {
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(height: 100, width: 100, quality: 100, transformOptions: { cropFocus: NORTH })
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            thumbnail {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(quality: 100)
                                    }
                                }
                            }
                        }
                        title
                        uri
                    }
                }
            }
            allWordpressProject(limit: 12, sort: { fields: date, order: DESC }) {
                edges {
                    node {
                        details {
                            services
                        }
                        featuredImage {
                            node {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(aspectRatio: 1, quality: 100)
                                    }
                                }
                            }
                        }
                        id
                        title
                        uri
                    }
                }
            }
            wordpressPage(isFrontPage: { eq: true }) {
                content
                header {
                    button {
                        target
                        title
                        url
                    }
                    heading
                    image {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100)
                            }
                        }
                    }
                    paragraph
                }
                seo {
                    canonical
                    metaDesc
                    metaKeywords
                    metaRobotsNofollow
                    metaRobotsNoindex
                    opengraphAuthor
                    opengraphDescription
                    opengraphModifiedTime
                    opengraphPublishedTime
                    opengraphPublisher
                    opengraphSiteName
                    opengraphTitle
                    opengraphType
                    opengraphUrl
                    schema {
                        raw
                    }
                    title
                }
                topImages {
                    topImageOne {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100)
                            }
                        }
                    }
                    topImageTwo {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100)
                            }
                        }
                    }
                }
            }
        }
    `);

    const onLongArrowDownIconClick = () => {
        const element = document.getElementById("top-images");

        if (!(element)) {
            return;
        }

        element.scrollIntoView({
            behavior: "smooth"
        });
    };

    useLayoutEffect(() => {
        setHeaderBackground("#E60000");

        setIsLogoColorless(true);
        setIsMenuColorless(true);
    }, []);

    return (
        <>
            <Seo { ...data.wordpressPage.seo } />
            <section className={ classNames.index } ref={ ref }>
                <header className={ classNames.header }>
                    <GatsbyImage
                        alt={ data.wordpressPage.header.image.altText }
                        className={ classNames.image }
                        image={ data.wordpressPage.header.image.localFile.childImageSharp.gatsbyImageData }
                    />
                    <div className={ classNames.container }>
                        <div className={ classNames.content }>
                            <h2 className={ classNames.heading }>{ data.wordpressPage.header.heading }</h2>
                            <p className={ classNames.paragraph }>{ data.wordpressPage.header.paragraph }</p>
                            <LinkButton
                                className={ classNames.button }
                                color="secondary"
                                to={ data.wordpressPage.header.button.url }
                                variant="contained"
                            >
                                <span>{ data.wordpressPage.header.button.title }</span>
                                <SmallArrowRightIcon className={ classNames.icon } />
                            </LinkButton>
                        </div>
                    </div>
                    <LongArrowDownIcon className={ classNames.icon } onClick={ onLongArrowDownIconClick } />
                    <div className={ classNames.logos }>
                        <img alt="Drentse Onderneming van het Jaar" className={ classNames.logo } height="75" src={ DOVHJ } width="60" />
                        <img alt="Dutch Digital Agencies" className={ classNames.logo } height="57" src={ DDAImage } width="76" />
                        <img alt="FD Gazelle" className={ classNames.logo } height="47" src={ FDG } width="149" />
                    </div>
                </header>
                <div className={ classNames.topImages } id="top-images">
                    <GatsbyImage
                        alt={ data.wordpressPage.topImages.topImageOne.altText }
                        className={ classNames.topImage }
                        image={ data.wordpressPage.topImages.topImageOne.localFile.childImageSharp.gatsbyImageData }
                    />
                    <GatsbyImage
                        alt={ data.wordpressPage.topImages.topImageTwo.altText }
                        className={ classNames.topImage }
                        image={ data.wordpressPage.topImages.topImageTwo.localFile.childImageSharp.gatsbyImageData }
                    />
                </div>
                { data.wordpressPage.content && (
                    <Gutenberg content={ data.wordpressPage.content } />
                ) }
                <div className={ classNames.ourWorkBlockWrapper }>
                    <OurWorkBlock projectEdges={ data.allWordpressProject.edges } />
                </div>
                <HowWeDoWhatWeDoBlock />
                <div className={ classNames.ourStoriesBlockWrapper }>
                    <OurStoriesBlock postEdges={ data.allWordpressPost.edges } />
                </div>
            </section>
        </>
    );
};

export default forwardRef(Index);
