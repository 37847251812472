import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type LongArrowDownIconProps
 */
export type LongArrowDownIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const LongArrowDownIcon
 */
const LongArrowDownIcon = (props: LongArrowDownIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="51.674" ref={ ref } viewBox="0 0 11.23 51.674" width="11.23">
            <path d="M 5.6054688 0.01171875 A 0.71657402 0.71657402 0 0 0 4.9003906 0.73828125 L 4.9003906 48.978516 L 1.4863281 45.564453 A 0.70006999 0.70006999 0 0 0 0.984375 45.351562 A 0.70006999 0.70006999 0 0 0 0.49609375 46.554688 L 4.9726562 51.03125 A 0.71657402 0.71657402 0 0 0 5.2988281 51.357422 L 5.6152344 51.673828 L 5.9277344 51.361328 A 0.71657402 0.71657402 0 0 0 6.2597656 51.029297 L 10.736328 46.554688 A 0.7002016 0.7002016 0 0 0 9.7460938 45.564453 L 6.3320312 48.980469 L 6.3320312 0.73828125 A 0.71657402 0.71657402 0 0 0 5.6054688 0.01171875 z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(LongArrowDownIcon);
